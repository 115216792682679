import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import moment from "moment"; // Importando moment para manipulação de datas

import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableWithPagination from "../../components/TableWithPagination";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
}));

const TicketsTag = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tag, setTag] = useState({ name: '', color: '' }); // Estado para armazenar os dados da etiqueta

  useEffect(() => {
    const fetchTicketsAndTag = async () => {
      setLoading(true);
      try {
        // Primeira req para obter os tickets
        const { data: ticketsData } = await api.get(`/tags/${id}/tickets`);
        const formattedTickets = ticketsData.map((ticket) => ({
          ...ticket,
          updatedAt: moment(ticket.updatedAt).format("DD/MM/YYYY HH:mm"),
          status: translateStatus(ticket.status),
        }));
  
        // Segunda requ para obter os dados da etiqueta
        const { data: tagData } = await api.get(`/ticket-tags/tag/${id}`);
        const { name, color } = tagData;
  
        // Atualizando o estado com os tickets e os dados da etiqueta
        setTickets(formattedTickets);
        setTag({ name, color });
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTicketsAndTag();
  }, [id]);
  

  const translateStatus = (status) => {
    switch (status) {
      case "open":
        return "Aberto";
      case "closed":
        return "Fechado";
      case "pending":
        return "Pendente";
      default:
        return status;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 50 },
    { field: "status", headerName: "Status", minWidth: 100 },
    {
      field: "contactName",
      headerName: "Contato",
      minWidth: 150,
      render: (value) => value || "N/A",
    },
    {
      field: "userName",
      headerName: "Atribuído",
      minWidth: 150,
      render: (value) => value || "N/A",
    },
    { field: "lastMessage", headerName: "Ulti. Mensagem", minWidth: 200 },
    { field: "updatedAt", headerName: "Ulti. Atualização", minWidth: 150 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    history.push(`/tickets/${row.id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.root}>
        <div style={{width: "100%", display: "flex", justifyContent: "flex-start"}}>
          <Title color={tag.color}>{tag.name}</Title>
        </div>
      <TableWithPagination
        columns={columns}
        data={tickets}
        rowsPerPageOptions={[10, 25, 50]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={tickets.length}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default TicketsTag;
