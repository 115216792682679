import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Board from "react-trello";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import TagSelect from "../../components/TagSelect";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "6px 10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    fontSize: "0.7rem",
    cursor: "pointer",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "6px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: 300,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  submitButton: {
    background: "#044842",
    color: "white",
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "#02322E",
    },
  },

  spanline: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "0 10px",
    minWidth: 300,
    fontWeight: "700",
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      width: "calc(50% - 20px)",
      height: "2px",
      backgroundColor: "#044842",
    },

    "&::before": {
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
    },

    "&::after": {
      top: "50%",
      right: 0,
      transform: "translateY(-50%)",
    },
  },

  input: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
}));

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [lanes, setLanes] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [file, setFile] = useState({ lanes: [] });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    fetchInitialData();
    fetchLanes();
  }, []);

  const initializeKanban = async () => {
    try {
      const response = await api.post("/kanban/init");
    } catch (error) {
      console.error("Erro ao inicializar Kanban:", error);
    }
  };

  const fetchInitialData = async () => {
    try {
      const response = await api.get("/kanban/tickets");
      const fetchedTickets = response.data || [];
      setTickets(fetchedTickets);
    } catch (error) {
      console.error("Erro ao carregar os tickets do Kanban.", error);
      toast.error("Erro ao carregar os tickets do Kanban.");
    }
  };

  const fetchLanes = async () => {
    try {
      const response = await api.post("/kanban/init");
      const fetchedLanes = response.data.lanes || [];
      console.log(response.data);
      setLanes(fetchedLanes);
    } catch (error) {
      console.error("Erro ao carregar as lanes do Kanban.", error);
      toast.error("Erro ao carregar as lanes do Kanban.");
    }
  };

  const popularCards = () => {
    const initialLane = {
      id: 0,
      title: i18n.t("Em aberto"),
      label: "0",
      disallowAddingCard: true,
      droppable: false,
      cards: tickets.map((ticket) => ({
        id: ticket.id.toString(),
        label: "Ticket nº " + ticket.id.toString(),
        description: (
          <div>
            <p>
              {ticket.contact.number}
              <br />
              {ticket.lastMessage}
            </p>
            <button
              className={classes.button}
              onClick={() => handleCardClick(ticket.id)}
            >
              Ver Ticket
            </button>
          </div>
        ),
        title: ticket.contact.name,
        draggable: true,
        href: "/tickets/" + ticket.id,
      })),
    };

    const additionalLanes = lanes.map((lane) => {
      // Recolhe todos os tickets da lane atual
      const laneTickets = lane.ticketLanes.map(
        (ticketLane) => ticketLane.ticket
      );

      // Inicializa os tickets a serem exibidos na lane atual
      let displayedTickets = laneTickets;

      if (lane.tagId) {
        // Se a lane tem um tagId, filtra os tickets pela tag correspondente
        displayedTickets = laneTickets.filter((ticket) =>
          ticket.ticketTags?.some((ticketTag) => ticketTag.tagId === lane.tagId)
        );
      }

      return {
        id: lane.id.toString(),
        title: lane.tag?.name || lane.title,
        label: displayedTickets.length.toString(),
        editLaneTitle: lane.tagId ? false : true,
        droppable: lane.tagId ? false : true,
        disallowAddingCard: true,
        cards: displayedTickets.map((ticket) => ({
          id: ticket.id.toString(),
          label: "Ticket nº " + ticket.id.toString(),
          description: (
            <div>
              <p>
                <span
                  style={{
                    fontWeight: "bold",
                    color: ticket.status === "open" ? "green" : "red",
                  }}
                >
                  {ticket.status === "open" ? "Aberto" : "Fechado"}
                </span>
                <br />
                <br />
                {ticket.contact.number}
                <br />
                {ticket.lastMessage}
              </p>
              <button
                className={classes.button}
                onClick={() => handleCardClick(ticket.id)}
              >
                Ver Ticket
              </button>
            </div>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.id,
        })),
        style: { backgroundColor: lane.tag?.color || "#ddd", color: "white" },
      };
    });

    // Adiciona todos os tickets às lanes relevantes
    lanes.forEach((lane) => {
      if (lane.tagId) {
        tickets.forEach((ticket) => {
          if (ticket.ticketTags?.some((tag) => tag.tagId === lane.tagId)) {
            const ticketExistsInLane = additionalLanes
              .find((l) => l.id === lane.id.toString())
              .cards.some((card) => card.id === ticket.id.toString());

            if (!ticketExistsInLane) {
              additionalLanes
                .find((l) => l.id === lane.id.toString())
                .cards.push({
                  id: ticket.id.toString(),
                  label: "Ticket nº " + ticket.id.toString(),
                  cardDraggable: false,
                  description: (
                    <div>
                      <p>
                        <span
                          style={{
                            fontWeight: "bold",
                            color: ticket.status === "open" ? "green" : "red",
                          }}
                        >
                          {ticket.status === "open" ? "Aberto" : "Fechado"}
                        </span>
                        <br />
                        <br />
                        {ticket.contact.number}
                        <br />
                        {ticket.lastMessage}
                      </p>
                      <button
                        className={classes.button}
                        onClick={() => handleCardClick(ticket.id)}
                      >
                        Ver Ticket
                      </button>
                    </div>
                  ),
                  title: ticket.contact.name,
                  href: "/tickets/" + ticket.id,
                });
            }
          }
        });
      }
    });

    setFile({ lanes: [initialLane, ...additionalLanes] });
  };

  const handleCardClick = (uuid) => {
    history.push("/tickets/" + uuid);
  };

  useEffect(() => {
    // if (tickets.length > 0) {
    popularCards();
    // }
  }, [tickets, lanes]);

  const handleCardMove = async (sourceLaneId, targetLaneId, cardId, index) => {
    try {
      if (targetLaneId != 0) {
        await api.put(`/kanban/cards/${cardId}/move`, {
          sourceLaneId,
          targetLaneId,
        });
        toast.success("Movimento de card processado com sucesso!");
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
      //toast.error("Erro ao processar movimento de card.");
    }
  };

  const handleAddNewLane = async (laneData) => {
    const title = laneData;
    try {
      await api.post("/kanban/lanes", {
        title,
        position: lanes.length,
      });
      window.location.reload();
    } catch (error) {
      console.error("Erro ao criar nova lane:", error);
    }
  };
  const handleLaneDelete = async (laneId) => {
    try {
      const response = await api.delete(`/kanban/lane/delete/${laneId}`);
      toast.success("Lane removida");
    } catch (error) {}
  };

  const handleCardDelete = async (cardId, laneId) => {
    if (laneId === 0) {
      return;
    }
    try {
      await api.delete(`/kanban/card/delete`, {
        data: { cardId: parseInt(cardId), laneId: parseInt(laneId) }, // Garante que são números inteiros
      });
      toast.success("Card removido");
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };

  const handleLaneUpdate = async (laneId, data) => {
    try {
      await api.post(`/kanban/lane/update/${laneId}`, {
        data: data,
      });
    } catch (error) {}
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleTagOrTitleSelect = () => {
    if (selectedTagId.length > 0) {
      handleTagSelect(selectedTagId);
    } else if (title !== null) {
      handleAddNewLane(title);
    }
  };

  const handleTagSelect = async (tagsId) => {
    try {
      await api.post("/kanban/lanes", {
        position: lanes.length,
        tagIds: tagsId,
      });
      toast.success("Nova lane adicionada.");
      handleCloseModal();
      window.location.reload(); // Recarrega a página para refletir as mudanças
    } catch (error) {
      console.error(`Erro ao criar nova lane por etiqueta:`, error);
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <button className={classes.button} onClick={handleOpenModal}>
            Nova Lane
          </button>
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            className={classes.modal}
          >
            <div className={classes.paper}>
              <h2 id="modal-title">Selecione a Etiqueta ou Insira um Título</h2>
              <TagSelect
                selectedTagIds={selectedTagId}
                onChange={setSelectedTagId}
              />
              <div
                style={{
                  position: "relative",
                  minWidth: 300,
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <span className={classes.spanline}>OU</span>
              </div>

              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  className={classes.input}
                  label="Título"
                  variant="outlined"
                  size="small"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>

              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  className={classes.submitButton}
                  onClick={handleTagOrTitleSelect}
                >
                  Adicionar
                </Button>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Board
            data={file}
            editable
            canAddLanes
            onLaneUpdate={handleLaneUpdate}
            onCardMoveAcrossLanes={handleCardMove}
            onLaneDelete={handleLaneDelete}
            onCardDelete={handleCardDelete}
            style={{ backgroundColor: "rgba(252, 252, 252, 0.03)" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Kanban;
